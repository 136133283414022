const emMagLock = {
  name: 'Door with mag lock',
  value: 1,
  relayTime: 6,
};

const strikeLock = {
  name: 'Door with strike lock',
  value: 2,
  relayTime: 6,
};

const boomBarierSlidingGate = {
  name: 'Boom Barrier or Sliding Gate',
  value: 3,
  relayTime: 2,
};

const flapTurnstileBarriers = {
  name: 'Flap or Tunstile Barrier',
  value: 4,
  relayTime: 2,
};

export const NEW_INSTALL_CONFIGS = [
  {
    name: 'Entry + Exit reader (RS485 Disabled)',
    value: 1,
    formOptions: {
      entry: true,
      exit: true,
      control: false,
      lockingMechanism: true,
      relayTime: true,
      attendanceAvailable: true,
    },
    validSerials: {
      entry: [
        '1001',
        '1002',
        '1015',
        '101F',
        '1023',
        '1024',
        '1025',
        '1028',
        '1029',
        '1033',
        '1034',
        '1038',
        '103A',
      ],
      exit: [
        '1001',
        '1002',
        '1015',
        '101F',
        '1023',
        '1024',
        '1025',
        '1028',
        '1029',
        '1033',
        '1034',
        '1038',
        '103A'
      ],
      control: [],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
      boomBarierSlidingGate: boomBarierSlidingGate,
      flapTurnstileBarriers: flapTurnstileBarriers,
    },
  },
  {
    name: 'Entry + Exit reader (RS485 Enabled)',
    value: 7,
    formOptions: {
      entry: true,
      exit: true,
      control: false,
      lockingMechanism: true,
      relayTime: true,
      attendanceAvailable: true,
    },
    validSerials: {
      entry: ['1028', '1029', '1001', '103A'],
      exit: ['1028', '1029', '1001', '103A'],
      control: [],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
      boomBarierSlidingGate: boomBarierSlidingGate,
      flapTurnstileBarriers: flapTurnstileBarriers,
    },
  },
  {
    name: 'Entry reader and REX',
    value: 2,
    formOptions: {
      entry: true,
      exit: false,
      control: false,
      lockingMechanism: true,
      relayTime: true,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: [
        '1001',
        '1002',
        '1015',
        '101F',
        '1023',
        '1024',
        '1025',
        '1028',
        '1029',
        '1033',
        '1034',
        '1038',
        '103A'
      ],
      exit: [],
      control: [],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
      boomBarierSlidingGate: boomBarierSlidingGate,
    },
  },
  // {
  //   name: 'Entry reader and 1-door controller',
  //   value: 'entryReaderAnd1DoorController',
  //   formOptions: {
  //     entry: true,
  //     exit: false,
  //     control: true,
  //     lockingMechanism: true,
  //     relayTime: true,
  //     attendanceAvailable: false,
  //   },
  //   validSerials: {
  //     entry: ['1001', '1002', '1015', '101F', '1023', '1028'],
  //     exit: [],
  //     control: ['1027'],
  //   },
  //   lockingMech: {
  //     emMagLock: emMagLock,
  //     strikeLock: strikeLock,
  //   },
  // },
  // {
  //   name: '1-door controller and REX',
  //   value: '1DoorControllerAndRex',
  //   formOptions: {
  //     entry: false,
  //     exit: false,
  //     control: true,
  //     lockingMechanism: true,
  //     relayTime: true,
  //     attendanceAvailable: false,
  //   },
  //   validSerials: {
  //     entry: [],
  //     exit: [],
  //     control: ['1027'],
  //   },

  //   lockingMech: {
  //     emMagLock: emMagLock,
  //     strikeLock: strikeLock,
  //   },
  // },
  {
    name: 'Clock-in device',
    value: 5,
    formOptions: {
      entry: true,
      exit: false,
      control: false,
      lockingMechanism: false,
      relayTime: false,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: [
        '1001',
        '1015',
        '101F',
        '1023',
        '1024',
        '1025',
        '1028',
        '1029',
        '1033',
        '1034', '103A',
        '1038',
      ],
      exit: [],
      control: [],
    },
    lockingMech: {},
  },
  {
    name: 'Door Lock',
    value: 6,
    formOptions: {
      entry: true,
      exit: false,
      control: false,
      lockingMechanism: false,
      relayTime: true,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: ['1016', '1021', '1026', '1032', '103B'],
      exit: [],
      control: [],
    },
    lockingMech: {},
  },
];

export const RETROFIT_TYPE1_CONFIGS = [
  {
    name: 'Entry reader and REX',
    value: 2,
    formOptions: {
      entry: true,
      exit: false,
      control: false,
      lockingMechanism: false,
      relayTime: false,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: ['1028', '1029', '1001', '101F', '1023', '1028', '1033', '1034', '103A', '1038'],
      exit: [],
      control: [],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
      boomBarierSlidingGate: boomBarierSlidingGate,
      flapTurnstileBarriers: flapTurnstileBarriers,
    },
  },
  {
    name: 'Entry and Exit reader',
    value: 1,
    formOptions: {
      entry: true,
      exit: true,
      control: false,
      lockingMechanism: false,
      relayTime: false,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: ['1028', '1029', '1001', '101F', '1023', '1028', '1033', '1034', '103A', '1038'],
      exit: ['1028', '1029', '1001', '101F', '1023', '1028', '1033', '1034', '103A', '1038'],
      control: [],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
      boomBarierSlidingGate: boomBarierSlidingGate,
    },
  },
];
export const CONTROLLER_CONFIGS = [
  {
    name: 'Entry Reader + Door Controller',
    value: 3,
    formOptions: {
      entry: true,
      exit: false,
      control: true,
      lockingMechanism: true,
      relayTime: true,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: ['1001', '1002', '1015', '101F', '1023', '1028'],
      exit: [],
      control: ['1027'],
    },
    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
    },
  },
  {
    name: 'Door Controller + REX',
    value: 4,
    formOptions: {
      entry: false,
      exit: false,
      control: true,
      lockingMechanism: true,
      relayTime: true,
      attendanceAvailable: false,
    },
    validSerials: {
      entry: [],
      exit: [],
      control: ['1027'],
    },

    lockingMech: {
      emMagLock: emMagLock,
      strikeLock: strikeLock,
    },
  },
];

export const CONTROLLER_LOCKING_MECHANISMS = [
  { name: 'Door with mag lock', value: 1 },
  {
    name: 'Door with strike lock',
    value: 2,
  },
];

export const controllerSerialConfigs = ['1027', '102C'];
